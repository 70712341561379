import React, { useEffect, useState } from 'react'
import cn from 'classnames'
import * as st from '~/assets/styl/WhatsApp.module.styl'

interface Props {
  location?: Location
}

const WhatsApp = ({ location }: Props) => {
  const [active, setActive] = useState(false)
  const [urlWhatsApp, setUrlWhatsApp] = useState('')
  const generalPhrase =
    'Estou no site da Aikon e gostaria de solicitar um atendimento, pode me ajudar?'
  const enterprisePhrase =
    'Estou no site da Aikon e gostei deste empreendimento, pode me ajudar?'

  useEffect(() => {
    !location
      ? setUrlWhatsApp(
          `https://api.whatsapp.com/send?phone=5547992866003&type=phone_number&app_absent=0&text=${generalPhrase}`
        )
      : setUrlWhatsApp(
          `https://api.whatsapp.com/send?phone=5547992866003&type=phone_number&app_absent=0&text=${enterprisePhrase} ${location.href}`
        )
  })

  return (
    <div className={st.core}>
      <button className={st.button} onClick={() => setActive(!active)}></button>
      <div className={cn(st.popup, active && st.active)}>
        <a href={urlWhatsApp} target="_blank" rel="noreferrer">
          Chamar no WhatsApp
        </a>
      </div>
    </div>
  )
}

export default WhatsApp
