export default [
  {
    name: 'instagram',
    href: 'https://www.instagram.com/aikonempreendimentos/',
  },
  // { name: 'facebook', href: '' },
  {
    name: 'linkedin',
    href: 'https://www.linkedin.com/company/aikonempreendimentos/',
  },
  {
    name: 'youtube',
    href: 'https://www.youtube.com/channel/UCUmJtrJsV9c1yRwhE3ge2Ug/featured',
  },
]
