import React, { Dispatch, SetStateAction } from 'react'
import { Props as EnterpriseProps } from '~/components/Enterprise'
import { Tabs } from '~/components'
import Slider, { ArrowType } from '@fil1pe/react-slider'
import cn from 'classnames'
import * as st from '~/assets/styl/Enterprises.module.styl'
import { EnterpriseCard } from '.'
import { useMediaQuery } from 'react-responsive'

interface Props {
  slider?: boolean
  title: string
  enterprises: Array<EnterpriseProps>
  className?: string
  separateSold?: boolean
  location?: Location
  enterprisesData?: Array<EnterpriseProps>
  setType?: Dispatch<SetStateAction<string>>
}

const Container = ({
  title,
  slider,
  location,
  enterprises,
  enterprisesData,
  setType,
}: Props) => {
  const tablet = useMediaQuery({
    query: '(max-width: 1279px)',
  })
  const mobile = useMediaQuery({
    query: '(max-width: 767px)',
  })

  function cardNumber() {
    if (mobile) {
      return 1
    } else if (tablet) {
      return 2
    } else {
      return 3
    }
  }

  const filterTabs = [
    'Balneário Camboriú',
    'Praia Brava',
    'Itajaí',
    'Ver todos',
  ]

  return (
    <div className={cn(st.container, { [st.slider]: slider })}>
      <h3>{title}</h3>
      {slider ? (
        <Slider
          className="slider"
          renderArrow={(props, type) => (
            <button
              {...props}
              className={cn('arrow', type === ArrowType.Prev && 'prev')}
            ></button>
          )}
          slidesToShow={cardNumber()}
          slidesToScroll={1}
        >
          {enterprises.map((enterprise, key) => (
            <EnterpriseCard key={key} {...enterprise} />
          ))}
        </Slider>
      ) : (
        <>
          {enterprisesData && enterprisesData.length > 0 ? (
            <Tabs
              tabs={filterTabs.map((name) => name)}
              isEnterprises
              setType={setType}
            >
              <ul className={st.listing}>
                {enterprisesData.map((enterprise, key) => (
                  <li key={key}>
                    <EnterpriseCard isEnterprises {...enterprise} />
                  </li>
                ))}
              </ul>
            </Tabs>
          ) : (
            <p className={st.notEnterprises}>
              Sem resultados para{' '}
              {`'${location.search
                .slice(8)
                .replace(/-/g, ' ')
                .replace(/\b\w/g, (c) => c.toUpperCase())}'`}
            </p>
          )}
        </>
      )}
    </div>
  )
}

const Enterprises = (props: Props) => {
  const { title, enterprises, className, separateSold = false } = props

  const launch = separateSold
    ? enterprises.filter(({ sold }) => !sold)
    : enterprises
  const sold = separateSold ? enterprises.filter(({ sold }) => sold) : []

  return (
    <section className={cn(st.core, className)}>
      {Boolean(!separateSold || launch.length) && (
        <Container {...props} title={title} enterprises={launch} />
      )}
      {Boolean(sold.length) && (
        <Container {...props} title="100% Vendidos" enterprises={sold} />
      )}
    </section>
  )
}

export default Enterprises
