import React, { ReactNode } from 'react'
import nl2br from 'react-nl2br'
import Slider, { ArrowType } from '@fil1pe/react-slider'
import { Button } from '~/components'
import { Image as ImageZoom } from '~/components/Lightbox'
//import { Image } from '~/components/ImageSection'
import { Lazyload as Image } from '~/components'
import cn from 'classnames'
import * as st from '~/assets/styl/MosaicSection.module.styl'
import { scroller } from 'react-scroll'
import { useMediaQuery } from 'react-responsive'

function menuBarHeight() {
  return -document.getElementById('menubar').offsetHeight
}

interface Props {
  knowMore?: boolean
  image?: string
  images?: Array<string | ImageZoom>
  children?: ReactNode
  contentChildren?: ReactNode
  zoom?: (index: number, images: Array<ImageZoom>) => void
  title: string
  description?: string | JSX.Element
  innerDescription?: string
  buttonLabel?: string
  buttonOutlined?: boolean
  onClick?: React.MouseEventHandler
  url?: string
  external?: boolean
  reverse?: boolean
  triangle?: boolean
  first?: boolean
  last?: boolean
  id?: string
  style?: React.CSSProperties
}

const MosaicSection = ({
  image,
  knowMore,
  images = [],
  children,
  contentChildren,
  zoom,
  title,
  description,
  innerDescription,
  buttonLabel,
  buttonOutlined,
  onClick,
  url,
  external,
  reverse,
  triangle,
  first,
  last,
  id,
  style = {},
}: Props) => {
  const large = useMediaQuery({
    query: '(min-width: 1101px)',
  })

  return (
    <section
      className={cn(
        st.core,
        reverse && st.reverse,
        triangle && st.triangle,
        first && st.first,
        last && st.last,
        !image && !images[0] && !children && st.concept
      )}
      id={id}
      style={style}
    >
      <div>
        <div className={st.text}>
          <h3>{nl2br(title)}</h3>
          {description && <p>{description}</p>}
          {innerDescription && (
            <p dangerouslySetInnerHTML={{ __html: innerDescription }}></p>
          )}
          <div className={st.ctas}>
            {knowMore && (
              <Button
                onClick={() =>
                  scroller.scrollTo('saiba-mais', {
                    smooth: true,
                    duration: 500,
                    offset: large ? 0 : menuBarHeight(),
                  })
                }
              >
                Saiba mais
              </Button>
            )}
            {buttonLabel &&
              (buttonOutlined ? (
                <button className={st.outlined} onClick={onClick}>
                  {buttonLabel}
                </button>
              ) : (
                <Button href={url} external={external} onClick={onClick}>
                  {buttonLabel}
                </Button>
              ))}
          </div>
          {contentChildren}
        </div>
      </div>
      {images.length > 1 ||
      (typeof images[0] !== 'string' && images[0]?.legend) ? (
        <Slider
          className={st.slider}
          renderArrow={(props, type) => (
            <button
              {...props}
              className={cn(st.arrow, type === ArrowType.Prev && st.prev)}
            ></button>
          )}
        >
          {images.map((image, key) => (
            <Image
              key={key}
              image={typeof image === 'string' ? image : image.urls.large}
              className={st.slide}
              onClick={() =>
                zoom?.(
                  key,
                  images.map((i) =>
                    typeof i === 'string'
                      ? { urls: { original: i } }
                      : { urls: i.urls, legend: i.legend }
                  )
                )
              }
              style={zoom ? { cursor: 'pointer' } : null}
            >
              {typeof image !== 'string' && image.legend && (
                <span>{image.legend}</span>
              )}
            </Image>
          ))}
        </Slider>
      ) : (
        <Image
          image={
            image ||
            (typeof images[0] === 'string' ? images[0] : images[0]?.urls.large)
          }
          onClick={() => {
            if (!zoom) return
            const _image = image || images[0]
            zoom(0, [
              typeof _image === 'string'
                ? { urls: { original: _image } }
                : { urls: _image.urls, legend: _image.legend },
            ])
          }}
          style={zoom ? { cursor: 'pointer' } : null}
          className={st.image}
        >
          {children}
        </Image>
      )}
    </section>
  )
}

export default MosaicSection
