import React from 'react'
import { Video } from '~/components'
import * as st from '~/assets/styl/SectionTransform.module.styl'
import Thumbnail from '~/assets/img/Thumbnail.jpg'
import { Button } from '~/components'
import { Link } from '~/components'

const SectionTransform = () => (
  <div className={st.core}>
    <div className={st.container}>
      <div className={st.image}></div>
      <section>
        <h2 className={st.title}>Transformar é moderno</h2>
        <p>
          Olhamos para as necessidades de um comprador moderno, que busca por
          design e conforto na escolha do seu investimento. Uma escolha
          inteligente e alinhada com o que existe de melhor.
          <br />
          <br />
          <strong>Morar em um Aikon é morar no futuro.</strong>
        </p>
        <div className={st.ctas}>
          <Button href="/sobre/">quem somos</Button>
          <div className={st.outlined}>
            <Link href="/empreendimentos/">nossos empreendimentos</Link>
          </div>
        </div>
      </section>
      <div className={st.videoWrapper}>
        <Video
          thumbnail={Thumbnail}
          src="https://www.youtube.com/watch?v=d-iiKhD-UmA"
          title="AIKON LIVING IN THE FUTURE"
        />
      </div>
      <section className={st.believe}>
        <h2>
          É sobre acreditar que o que fazemos hoje, fará o amanhã um lugar
          melhor.
        </h2>
      </section>
    </div>
  </div>
)

export default SectionTransform
