/* eslint-disable react/prop-types */
import React from 'react'
import { Loading } from '~/components'
import { Image } from '~/components/ImageSection'
import { LazyImageFull, ImageState } from 'react-lazy-images'

const LazyLoad = ({ image: src, ...props }) => {
  return (
    <LazyImageFull src={src}>
      {({ imageState, ref }) =>
        imageState === ImageState.LoadSuccess ? (
          <Image {...props} image={src} ref={ref} />
        ) : (
          <Loading {...props} ref={ref} staticStyle />
        )
      }
    </LazyImageFull>
  )
}

export default LazyLoad
