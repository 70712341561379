import React, {
  Children,
  cloneElement,
  useState,
  Dispatch,
  SetStateAction,
  useEffect,
} from 'react'
import { useMediaQuery } from 'react-responsive'
import cn from 'classnames'
import * as st from '~/assets/styl/Tabs.module.styl'
import Link from './Link'
import { useLocation } from '@reach/router'

interface Props {
  children: React.ReactNode
  tabs: Array<string>
  outlined?: boolean
  default?: number
  isEnterprises?: boolean
  setType?: Dispatch<SetStateAction<string>>
}

const Tabs = ({
  children,
  tabs,
  outlined = false,
  default: _default = 0,
  setType,
  isEnterprises,
}: Props) => {
  const location = useLocation()
  const params = new URLSearchParams(location?.search)
  const search = params.get('search')

  const [active, setActive] = useState(() => {
    if (search) {
      const activeTabIndex = tabs.findIndex(
        (tab) =>
          search.replace('-', ' ').toLowerCase() ===
          removeAccentuation(tab.toLowerCase().replace('-', ' '))
      )

      if (activeTabIndex !== -1) {
        return activeTabIndex
      }
    }

    return _default
  })

  useEffect(() => {
    if (!isEnterprises) {
      setActive(_default)
    }
  }, [_default])

  const desktop = useMediaQuery({
    query: '(min-width: 601px)',
  })

  function removeAccentuation(texto: string) {
    return texto.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
  }

  useEffect(() => {
    if (!search) {
      setActive(_default)
    }

    if (search) {
      const activeTabIndex = tabs.findIndex(
        (tab) =>
          search.replace('-', ' ').toLowerCase() ===
          removeAccentuation(tab.toLowerCase().replace('-', ' '))
      )

      if (activeTabIndex !== -1) {
        setActive(activeTabIndex)
      }
    }
  }, [location.href])

  return (
    <>
      {tabs.length > 1 && (
        <ul className={cn(st.selector, outlined && st.outlined)}>
          {tabs.map((tab, index) => (
            <li
              className={cn({
                [st.active]: active === index,
                [st.active2]: search === removeAccentuation(tab),
                [st.link]: isEnterprises,
              })}
              onClick={() => {
                setActive(index)
                if (isEnterprises) {
                  if (tab === 'Ver todos') {
                    setType('')
                  } else {
                    setType(tab)
                  }
                }
              }}
              key={index}
            >
              {!isEnterprises && tab}
              {isEnterprises && (
                <Link
                  href={
                    tab.toLowerCase() === 'ver todos'
                      ? '/empreendimentos/'
                      : `/empreendimentos/?search=${removeAccentuation(
                          tab.toLowerCase().replace(' ', '-')
                        )}`
                  }
                >
                  {tab}
                </Link>
              )}
            </li>
          ))}
          {!outlined && (
            <li
              style={{
                width: desktop
                  ? `${100 / tabs.length}%`
                  : `${
                      active === tabs.length - 1 && active % 2 === 0 ? 100 : 50
                    }%`,
                left: desktop
                  ? `${active * (100 / tabs.length)}%`
                  : `${active % 2 === 0 ? 0 : 50}%`,
              }}
              className={st.bar}
            ></li>
          )}
        </ul>
      )}
      <div className={cn(st.content, 'tabs-content')}>
        {Children.toArray(children)
          .filter((child) => child)
          .map((child: React.ReactElement, key) =>
            cloneElement(child, {
              className: cn(
                child.props.className,
                key === active && st.active,
                { [st.activeEnterprises]: isEnterprises }
              ),
            })
          )}
      </div>
    </>
  )
}

export default Tabs
