import React, { useContext } from 'react'
import { context } from '~/layouts'
import styled, { css } from 'styled-components'
import * as st from '~/assets/styl/Hero.module.styl'
import Logo from '~/assets/svg/Logo-simple.svg'
import LogoHome from '~/assets/img/Logo-white.png'

interface HeroWrapperInterface {
  image: string
  film?: boolean
}

function film(apply?: boolean) {
  if (apply)
    return 'linear-gradient(0deg, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0.3) 100%), '
  return ''
}

const HeroWrapper = styled.div<HeroWrapperInterface>`
  position: relative;
  min-height: 100vh;
  color: #f8f8f8;
  ${(props) =>
    props.image &&
    css`
      background: ${film(props.film)}url(${props.image}) center / cover;
    `}
`

interface Props {
  image: string
  title?: React.ReactNode
  subtitle?: string // Não usado mais
  tag?: string
  location?: string
}

const Hero = ({ image, title, tag, location }: Props) => {
  const { setHamburgerOpen } = useContext(context)

  return (
    <HeroWrapper
      image={image}
      className={title ? st.core : st.home}
      film={Boolean(title)}
    >
      <button className={st.hamburger} onClick={() => setHamburgerOpen(true)}>
        Menu
      </button>
      {title ? (
        <div className={st.container}>
          {tag && <span className={st.tag}>{tag}</span>}
          <h1>{title}</h1>
          <img src={Logo} alt="AIKON" width="244" height="57" />
          {location && <span className={st.location}>{location}</span>}
        </div>
      ) : (
        <div>
          <img src={LogoHome} alt="AIKON" width="220" height="67" />
          <section className={st.title}>
            <h1>
              <span>Aikon Empreendimentos Living in the future</span>
            </h1>
          </section>
        </div>
      )}
    </HeroWrapper>
  )
}

export default Hero
