// extracted by mini-css-extract-plugin
export var business = "Team-module--business--jXxKq";
export var certs = "Team-module--certs--WzKZy";
export var close = "Team-module--close--Xt2tD";
export var container = "Team-module--container--Bi6TY";
export var content = "Team-module--content--FBE7i";
export var core = "Team-module--core--UGF9e";
export var director = "Team-module--director--NS137";
export var image = "Team-module--image--da4Kz";
export var iso = "Team-module--iso--C07aa";
export var mate = "Team-module--mate--hySUj";
export var mates = "Team-module--mates--lO-Kp";
export var modal = "Team-module--modal--Wpk3F";
export var number = "Team-module--number--945mW";
export var policy = "Team-module--policy--lcoTr";
export var spiral1 = "Team-module--spiral1--d+UJJ";
export var spiral2 = "Team-module--spiral2--wnzUB";
export var team = "Team-module--team--qyU02";