import React from 'react'
import { scroller } from 'react-scroll'
import { Video, Button } from '~/components'
import * as st from '~/assets/styl/About.module.styl'
import Thumbnail from '~/assets/img/Thumbnail.jpg'
import Man from '~/assets/img/Homem.jpg'

const About = () => (
  <section className={st.core}>
    <div className={st.container}>
      <div className={st.video}>
        <div>
          <h3>A AIKON é a visão do novo</h3>
          <p>
            Olhamos para as necessidades de um comprador moderno, que busca por
            design e conforto na escolha do seu investimento. Uma escolha
            inteligente e alinhada com o que existe de melhor.
            <br />
            <br />
            <strong>Morar em um Aikon é morar no futuro.</strong>
          </p>
        </div>
        <div>
          <Video
            thumbnail={Thumbnail}
            src="https://www.youtube.com/watch?v=d-iiKhD-UmA"
            title="AIKON LIVING IN THE FUTURE"
          />
        </div>
      </div>
      <h3>
        Promovendo os melhores resultados para transformar o bem-viver de seus
        clientes.
      </h3>
      <div className={st.textBlock}>
        <img src={Man} width="812" height="1054" />
        <div>
          <h3>Viva novas experiências!</h3>
          <p>
            Aprimoramos investimentos no segmento imobiliário no sul do Brasil.
            <br />
            <br />
            Com uma equipe especializada com foco em desenvolver os melhores
            resultados para todos que se engajam no negócio.
          </p>
          <Button
            onClick={() =>
              scroller.scrollTo('ModeloDeNegocio', {
                smooth: true,
                duration: 500,
              })
            }
          >
            Conheça nosso modelo de negócio
          </Button>
        </div>
      </div>
    </div>
  </section>
)

export default About
