import React, { useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import cn from 'classnames'
import * as st from '~/assets/styl/HowWeDo.module.styl'

interface Props {
  tabs: Array<{
    name: string
    description: string
  }>
}

const HowWeDo = ({ tabs }: Props) => {
  const [active, setActive] = useState(0)
  const desktop = useMediaQuery({
    query: '(min-width: 701px)',
  })

  return (
    <section className={st.core} id="ComoFazemos">
      <div className={st.container}>
        <h3>Nosso Propósito</h3>
        <div className={st.tabs}>
          {desktop ? (
            <div>
              <div className={st.number}>
                <div style={{ transform: `translateY(${-100 * active}%)` }}>
                  {tabs.map((_, key) => (
                    <span key={key}>{key + 1}</span>
                  ))}
                </div>
              </div>
              <div className={st.tab}>
                {tabs.map(({ name, description }, key) => (
                  <div key={key} className={cn(key === active && st.active)}>
                    <h4>{name}</h4>
                    <p>{description}</p>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            tabs.map(({ name, description }, key) => (
              <div key={key}>
                <div className={st.number}>
                  <div>
                    <span key={key}>{key + 1}</span>
                  </div>
                </div>
                <div className={st.tab}>
                  <div key={key} className={st.active}>
                    <h4>{name}</h4>
                    <p>{description}</p>
                  </div>
                </div>
              </div>
            ))
          )}
          <ul className={st.selector}>
            {tabs.map(({ name }, key) => (
              <li key={key}>
                <div
                  className={cn(st.tab, key === active && st.active)}
                  onClick={() => setActive(key)}
                >
                  <span>{key + 1}</span>
                  {name}
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  )
}

export default HowWeDo
