import React from 'react'
import Slider, { ArrowType } from '@fil1pe/react-slider'
import { Button, Form } from '~/components'
import cn from 'classnames'
import * as st from '~/assets/styl/Testimonials.module.styl'
import * as st1 from '~/assets/styl/Contact.module.styl'

interface Props {
  form?: boolean
  testimonials: Array<{
    name: string
    description: string
    image: string
  }>
}

const Testimonials = ({ form, testimonials }: Props) => {
  return Boolean(testimonials.length) || form ? (
    <section className={st.core}>
      {Boolean(testimonials.length) && (
        <div className={st.container}>
          <h3>Depoimentos</h3>
          <Slider
            className="slider"
            renderArrow={(props, type) => (
              <button
                {...props}
                className={cn('arrow', type === ArrowType.Prev && 'prev')}
              ></button>
            )}
          >
            {testimonials.map(({ image, description: text, name }, key) => (
              <div key={key} className={st.testimonial}>
                <img alt={name} src={image} width="250" height="250" />
                <span className={st.author}>{name}</span>
                <p>{text}</p>
              </div>
            ))}
          </Slider>
        </div>
      )}

      {form && (
        <div className={cn(st1.container, st.form)} id="faca-parte">
          <div className={st1.forms}>
            <div>
              <div>
                <h3>Faça parte!</h3>
                <p>
                  Se você deseja fazer parte da equipe de vendas da Aikon,
                  utilize o formulário ao lado para entrar em contato.
                </p>
              </div>
              <Form
                idPrefix="broker"
                rdStation="site-seja-um-corretor"
                button={<Button>Enviar mensagem</Button>}
                loadingButton={<Button>Carregando...</Button>}
                hiddenInputs={{
                  legal_bases: [
                    {
                      category: 'communications',
                      type: 'consent',
                      status: 'granted',
                    },
                    {
                      category: 'data_processing',
                      type: 'consent',
                      status: 'granted',
                    },
                  ],
                }}
                inputs={[
                  {
                    label: 'Nome*',
                    name: 'name',
                  },
                  {
                    label: 'E-mail*',
                    name: 'email',
                    type: 'email',
                  },
                  {
                    label: 'Telefone*',
                    name: 'phone',
                    mask: 'phone',
                  },
                  {
                    label: 'WhatsApp*',
                    name: 'whatsapp',
                    mask: 'phone',
                  },
                  {
                    label: 'Mensagem*',
                    name: 'message',
                    type: 'textarea',
                    className: st1.wide,
                  },
                  {
                    label: (
                      <>Declaro que li e aceito a Política de Privacidade</>
                    ),
                    name: 'privacy',
                    type: 'checkbox',
                    className: st1.privacy,
                  },
                ]}
              />
            </div>
          </div>
        </div>
      )}
    </section>
  ) : (
    <></>
  )
}

export default Testimonials
