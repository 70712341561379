// extracted by mini-css-extract-plugin
export var FaleConosco = "Contact-module--FaleConosco--TXu6C";
export var ParceiroAikon = "Contact-module--ParceiroAikon--F0phO";
export var TrabalheConosco = "Contact-module--TrabalheConosco--pPvIs";
export var container = "Contact-module--container--iyb+g";
export var core = "Contact-module--core--3TacH";
export var forms = "Contact-module--forms--7cBG2";
export var head = "Contact-module--head--bdh7b";
export var image = "Contact-module--image--+wwXx";
export var privacy = "Contact-module--privacy--nvITg";
export var search = "Contact-module--search--zN300";
export var tab = "Contact-module--tab--60BxT";
export var text = "Contact-module--text--kxIqi";
export var whatsapp = "Contact-module--whatsapp--sKU9G";
export var wide = "Contact-module--wide--bh2Dx";