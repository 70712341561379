import React, { useRef, useState, useEffect } from 'react'
import { Modal } from '~/components'
import { Image } from '~/components/ImageSection'
import cn from 'classnames'
import * as st from '~/assets/styl/Video.module.styl'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const YT: { Player: any }

export function getVideoId(url: string) {
  const urlParts = url.split(/(youtu\.be\/)|(youtube\.com\/watch\?v=)/)
  return urlParts[urlParts.length - 1]
}

type Props = {
  src: string
  thumbnail: string
  title?: string
  className?: string
}

const VideoModal = (
  src: string,
  thumbnail: string,
  [playing, setPlaying]: [number, React.Dispatch<React.SetStateAction<number>>]
) => (
  <>
    <Image
      image={thumbnail}
      className={st.miniature}
      onClick={() => setPlaying(1)}
      film
    />
    <Modal
      open={Boolean(playing)}
      onClose={() => setPlaying(0)}
      className={st.modal}
    >
      {(closeProps) => (
        <>
          <button {...closeProps}></button>
          <div>
            <iframe
              src={`https://www.youtube.com/embed/${getVideoId(
                src
              )}?enablejsapi=1`}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </>
      )}
    </Modal>
  </>
)

const Video = ({ src: url, thumbnail, title, className }: Props) => {
  const [playing, setPlaying] = useState(0)
  if (!title) return VideoModal(url, thumbnail, [playing, setPlaying])
  const ref = useRef()
  const [hover, setHover] = useState(false)

  useEffect(() => {
    if (playing !== 1) return
    const f = setInterval(() => {
      if (!YT.Player) return
      const player = new YT.Player(ref.current, {
        videoId: getVideoId(url),
        events: {
          onReady: () => player.playVideo(),
          onStateChange: ({ data: code }) => {
            if (code === 1) setPlaying(2)
          },
        },
      })
      clearInterval(f)
    }, 100)
  }, [playing])

  return (
    <div
      className={cn(
        st.core,
        (playing === 1 || hover) && st.hover,
        playing === 2 && st.playing,
        className
      )}
    >
      <Image className={st.thumbnail} image={thumbnail} film>
        <div className={st.legend}>
          <span>Play</span>
          <div
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            onClick={() => playing === 0 && setPlaying(1)}
          ></div>
          <span>{title}</span>
        </div>
      </Image>
      <div ref={ref}></div>
    </div>
  )
}

export default Video
