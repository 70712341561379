import Iso from '~/assets/img/certifications/iso-blue-large.png'
import Iso14 from '~/assets/img/certifications/iso-blue14-large.png'
import GPTW from '~/assets/img/certifications/gptw-large.png'
import PBQP from '~/assets/img/certifications/pbqp-large.png'
import intec from '~/assets/img/certifications/intec-large.png'

export default [
  {
    title: 'Great Place to Work (GPTW)',
    image: GPTW,
    width: 118,
    height: 200,
    description: `
    <p>
      Somos uma das melhores empresas para trabalhar, conquistamos o selo de
      certificação Great Place to Work - GPTW 2023. Essa premiação reflete o
      comprometimento da nossa equipe, o bem-estar dos colaboradores e a
      confiança depositada em fazer parte de um time com alto desempenho.
    </p>
    <br />
    <p>
      <i
        >“Ser reconhecida como uma excelente empresa para se trabalhar tem muito
        significado para nós, além de fortalecer a Aikon como referência,
        acreditamos no impacto dos resultados que um bom ambiente de trabalho
        pode causar na vida de todas as pessoas.”</i
      >&nbsp;<b>Emerson Granado - Sócio e fundador da Aikon Empreendimentos. </b>
    </p>
    `,
  },
  {
    title: 'ISO 9001:2015',
    image: Iso,
    width: 180,
    height: 180,
    description: `
    <p>
      O Sistema de gestão da qualidade da AIKON Empreendimentos, encontra-se em
      conformidade com os requisitos da norma: ABNT NBR ISO 9001:2015.
    </p>
    <br />
    <p>
      Certificação que define diretrizes de gestão da qualidade nos processos de
      execução de obras e edificações. O selo ISO 9001:2015 também certifica
      setores da Construção Civil que por meio um conjunto de práticas e
      metodologias possui o intuito de atender os objetivos propostos,
      principalmente nos quesitos padrão de qualidade de seus produtos e
      serviços
    </p>
    <br />
    <p>
      <i
        >“Entendemos que obter essa certificação nos auxiliará nos mapeamentos
        de processos internos e externos, além da melhoria na padronização de
        serviços, mapeamento de indicativos e nas implementações de melhorias na
        Construtora.”</i
      >&nbsp;
      <b>Thiago Schenkel - Sócio e fundador da Aikon Empreendimentos. </b>
    </p>
    <br />
    <p>
      Adotamos sistemas de gestão de qualidade em todos os nossos processos
      internos e externos, estabelecendo, por exemplo, uniformidade na qualidade
      de materiais, serviços, redução de riscos, treinamento da equipe,
      iniciativas sustentáveis, para atendimento aos requisitos dos clientes e
      da sociedade.
    </p>`,
  },
  {
    title: 'ISO 14001:2015',
    image: Iso14,
    width: 180,
    height: 180,
    description: `
    <p>
      Somos uma construtora que atende aos requisitos de um Sistema de Gestão Ambiental (SGA) 
      de qualidade. Estamos em conformidade com a Certificação ISO 14001, norma 
      reconhecida internacionalmente para um cuidado sustentável em todos os processos construtivos.
    </p>
    <br />
    <p>
      A ISO 14001 garante que atendemos um conjunto de requisitos sobre 
      implementação, manutenção e melhoria contínua de um SGA efetivo. Além disso, 
      demonstra o quanto estamos capacitados para identificar precocemente riscos 
      e impactos ambientais em nossas obras, refletindo em um futuro sustentável para todos.

    </p>
    <br />
    <p>
      Monitoramos constantemente com auditorias, ações sustentáveis e a 
      capacitação contínua do nosso time de colaboradores, em diversas áreas.
    </p>`,
  },
  {
    title: 'PBQP-H - SIAC 2021',
    image: PBQP,
    width: 185,
    height: 130,
    description: `
    <p>
      O Sistema de avaliação da conformidade de empresas de serviços e obras da
      construção civil da AIKON Empreendimentos, encontra-se em conformidade com
      os requisitos da norma: PBQP-H SIAC 2021 NÍVEL A.
    </p>
    <br />
    <p>
      O selo Programa Brasileiro da Qualidade e Produtividade do Habitat
      (PBQP-H) - SIAC 2021 é uma certificação de gestão da qualidade, exclusivo
      para construtoras. Seu foco está na otimização de processos de execução de
      obras para que as empresas de Construção Civil ofereçam serviços e
      produtos da mais alta qualidade, além do cuidado e aperfeiçoamento
      administrativo.
    </p>
    <br />
    <p>
      A certificação busca garantir dois pontos fundamentais quando se fala de
      habitação de interesse social: a qualidade, com obras marcadas pela
      segurança e durabilidade, além da produtividade do setor da construção a
      partir da sua modernização.
    </p>
    <br />
    <p>
      Detalhes nos processos de execução das obras é a nossa prioridade. Somos
      certificados com o selo <b> PBQP-H - SIAC 2021</b>, comprovando nossa
      excelência em procedimentos de armazenamento de materiais, segurança e
      planejamento das obras.
    </p>
    `,
  },
  {
    title: 'Ranking do INTEC 2024',
    image: intec,
    width: 173,
    height: 149,
    description: `
    <p>
      A INTEC Brasil é uma plataforma de prospecção de obras e, anualmente,
      premia as 100 maiores construtoras de atuação nacional com o melhor metro
      quadrado construído, com dados superiores aos alcançados no ano anterior.
    </p>
    <br />
    <p>
      O ranking acontece desde 2006, sendo a maior premiação da Construção
      Civil, apontando novas tendências dos métodos construtivos e os desafios
      superados. O resultado é decidido a partir da validação da autodeclaração
      dos números da metragem dos empreendimentos enviados pelas construtoras
      com a base de dados da plataforma e pesquisas de campo.
    </p>
    <br />
    <p>
      A Aikon foi contemplada com o 90º lugar na posição do Ranking INTEC 2024, 
      considerando a 7ª construtora com maior m² no estado de Santa Catarina.
    </p>
    <br />
    <p>
      Investimentos que vêm sendo aplicados com o foco no bem-viver das pessoas,
      oferecendo empreendimentos completos e valorizados nas cidades de
      Balneário Camboriú, Praia Brava e Itajaí.
    </p>
    <br />
    <p>
      Esse resultado reflete a nossa atenção à inovação já inserida em nossos
      projetos e a nossa visão em atender às necessidades dos nossos clientes.
    </p>
    `,
  },
]
