import React from 'react'
import { Link as GatsbyLink } from 'gatsby'

type Props = React.DetailedHTMLProps<
  React.AnchorHTMLAttributes<HTMLAnchorElement>,
  HTMLAnchorElement
> & { external?: boolean; href: string }

const Link = ({ external, href, ...props }: Props) => {
  if (external)
    return <a href={href} target="_blank" rel="noreferrer" {...props}></a>
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return <GatsbyLink to={href} {...props}></GatsbyLink>
}

export default Link
