// extracted by mini-css-extract-plugin
export var arrow = "MosaicSection-module--arrow--ovEQ6";
export var concept = "MosaicSection-module--concept--ndyLc";
export var core = "MosaicSection-module--core--N8nrI";
export var ctas = "MosaicSection-module--ctas--xYZW4";
export var first = "MosaicSection-module--first--cV3QP";
export var image = "MosaicSection-module--image--XRLYo";
export var last = "MosaicSection-module--last--uyth4";
export var outlined = "MosaicSection-module--outlined--7uphm";
export var prev = "MosaicSection-module--prev--1fvSo";
export var reverse = "MosaicSection-module--reverse--G9yZp";
export var slide = "MosaicSection-module--slide--GhV1m";
export var slider = "MosaicSection-module--slider--7fRvr";
export var text = "MosaicSection-module--text--r5oqk";
export var triangle = "MosaicSection-module--triangle--FEZM8";