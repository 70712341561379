import React, { MouseEventHandler } from 'react'
import { Link } from '~/components'
import cn from 'classnames'
import * as st from '~/assets/styl/Button.module.styl'

interface Props {
  children: string
  href?: string
  external?: boolean
  className?: string
  onClick?: MouseEventHandler
}

const Button = ({ children, href, external, className, onClick }: Props) =>
  href ? (
    <Link
      href={href}
      external={external}
      className={cn(st.core, className)}
      onClick={onClick}
    >
      <span>{children}</span>
    </Link>
  ) : (
    <button className={cn(st.core, className)} onClick={onClick}>
      <span>{children}</span>
    </button>
  )

export default Button
