import React, { Fragment, useState } from 'react'
import { Button, Modal } from '~/components'
import { Image } from '~/components/ImageSection'
import cn from 'classnames'
import * as st from '~/assets/styl/Team.module.styl'

import People from '~/assets/img/Pessoas.jpg'
import People2 from '~/assets/img/Pessoas2.jpg'
import Men from '~/assets/img/Homens.jpg'

import Iso from '~/assets/img/certifications/iso-blue-large.png'
import Iso14 from '~/assets/img/certifications/iso-blue14-large.png'
import GPTW from '~/assets/img/certifications/gptw-large.png'
import PBQP from '~/assets/img/certifications/pbqp-large.png'
import intec from '~/assets/img/certifications/intec-large.png'

interface Props {
  directorship?: Array<{
    name: string
    description: string
    image: string
  }>
  team?: string
  tradings?: Array<{
    name: string
    resume: string
    description: Array<string>
  }>
  mates: Array<{
    name: string
    office: string
    image: string
  }>
}

const Team = ({ directorship, team = 'Equipe', tradings, mates }: Props) => {
  const [modal, setModal] = useState(-1)

  return (
    <section className={cn(st.core, directorship ? st.spiral1 : st.spiral2)}>
      <div className={st.container}>
        {directorship ? (
          <>
            <h3>Sócios e fundadores</h3>
            {directorship.map(({ image, description, name }, key) => (
              <Fragment key={key}>
                <div className={st.director}>
                  <img src={image} width="398" height="555" alt={name} />
                  <div>
                    <h4>{name}</h4>
                    <p>{description}</p>
                  </div>
                </div>
              </Fragment>
            ))}
          </>
        ) : (
          <div className={st.team}>
            <h3>
              Corretor, seja nosso parceiro! Conheça com exclusividade nossos
              empreendimentos e as melhores condições para alavancar os seus
              negócios.
            </h3>
            <Image image={People} className={st.image} />
            <div>
              <h4>Somos especialistas na administração e vendas de imóveis.</h4>
              <div>
                <p>
                  Tenha acesso direto aos nossos materiais, participe de
                  treinamentos, acompanhe o desenvolvimento das nossas obras e
                  receba o suporte necessário para transformar a maneira de
                  vender imóveis.
                </p>
                <Button href="https://aikon.cvcrm.com.br/corretor/" external>
                  Cadastre-se
                </Button>
              </div>
            </div>
          </div>
        )}
        {mates.length > 0 && (
          <>
            <h3>{team}</h3>
            <ul className={st.mates}>
              {mates.map(({ name, office, image }, key) => (
                <li key={key}>
                  <div className={st.mate}>
                    <Image image={image} className={st.image} />
                    <h4>{name}</h4>
                    <p>{office}</p>
                  </div>
                </li>
              ))}
            </ul>
          </>
        )}
        {tradings && (
          <>
            <h3 id="ModeloDeNegocio">Modelo de negócio</h3>
            <ul className={st.business}>
              {tradings.map(({ name, resume: description }, key) => (
                <li key={key}>
                  <span className={st.number}>{key + 1}</span>
                  <div>
                    <h4>{name}</h4>
                    <p>{description}</p>
                    <Button onClick={() => setModal(key)}>Saiba mais</Button>
                  </div>
                </li>
              ))}
            </ul>

            {tradings.map(
              ({ name, resume: description, description: items }, key) => (
                <Modal
                  key={key}
                  open={modal === key}
                  onClose={() => setModal(-1)}
                  className={st.modal}
                >
                  {(closeProps) => (
                    <>
                      <Image image={key ? People2 : Men} className={st.image}>
                        <button {...closeProps} className={st.close} />
                      </Image>
                      <div className={st.content}>
                        <h3>{name}</h3>
                        <p>{description}</p>
                        <ul>
                          {items.map((item, key) => (
                            <li key={key}>{item}</li>
                          ))}
                        </ul>
                      </div>
                    </>
                  )}
                </Modal>
              )
            )}

            <h4 className={st.policy}>
              Política do Sistema de Gestão Integrado
            </h4>
            <p>
              A empresa está comprometida a manter e aprimorar continuamente o
              Sistema de Gestão Integrado (SGI) da AIKON, sustentando a
              qualidade como nosso pilar estratégico. Para alcançar esse
              compromisso, adotamos as seguintes diretrizes:
            </p>
            <ul>
              <li>Transparência e relacionamento com clientes;</li>
              <li>
                Sustentabilidade no canteiro de obras e prevenção de poluição;
              </li>
              <li>
                Conformidade com requisitos legais e das partes interessadas;
              </li>
              <li>Desenvolvimento e valorização das pessoas;</li>
              <li>Referência em produto para o cliente;</li>
              <li>Inovação nos processos;</li>
              <li>Excelência na execução das obras.</li>
            </ul>

            <h4 className={st.certs}>Certificações</h4>
            <div>
              <div>
                <img src={Iso} width="150" height="150" alt="ISO 9001" />
                <img src={Iso14} width="150" height="150" alt="ISO 14001" />
                <img
                  src={GPTW}
                  width="89"
                  height="150"
                  alt="Great Place to Work"
                />
                <img src={PBQP} width="185" height="130" alt="PBQP-H" />
                <img src={intec} width="173" height="149" alt="Ranking Intec" />
              </div>
            </div>
            <p className={st.iso}>
              <span>Escopo da NBR ISO 14001:2015</span>
              Incorporação, Comercialização, Gerenciamento e Execução de obras
              de edificações residenciais e comerciais.
            </p>
          </>
        )}
      </div>
    </section>
  )
}

export default Team
