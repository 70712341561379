// Aqui os dados do head das páginas

import React from 'react'
import Helmet from 'react-helmet'

interface Props {
  title?: string
  description?: string
  location?: Location
}

const DEFAULT_TITLE = process.env.GATSBY_SITE_NAME
const DEFAULT_DESCRIPTION =
  'Descubra nossos empreendimentos em Balneário Camboriú, Praia Brava e Itajaí.'

const Head = ({ title, description, location }: Props) => (
  <Helmet>
    <meta
      property="og:image"
      itemProp="image"
      content={`https://${process.env.GATSBY_SITE_URL}/opengraph.jpg?v=1`}
    />
    <meta
      name="twitter:image"
      content={`https://${process.env.GATSBY_SITE_URL}/opengraph.jpg?v=1`}
    />
    <title>{title || DEFAULT_TITLE}</title>
    <meta property="og:title" content={title || DEFAULT_TITLE} />
    <meta property="og:site_name" content={process.env.GATSBY_SITE_NAME} />
    <meta name="description" content={description || DEFAULT_DESCRIPTION} />
    <meta
      property="og:description"
      content={description || DEFAULT_DESCRIPTION}
    />
    <link
      rel="canonical"
      href={`https://${process.env.GATSBY_SITE_URL}${location?.pathname || ''}`}
    />
    <meta
      property="og:url"
      content={`https://${process.env.GATSBY_SITE_URL}${
        location?.pathname || ''
      }`}
    />
    <link rel="shortcut icon" href="/favicon.ico" type="image/x-icon" />
    <meta property="og:locale" content="pt_BR" />
    <meta charSet="utf-8" />
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1, shrink-to-fit=no"
    />
    <link
      rel="stylesheet"
      href="https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700&display=swap"
    />
    <script src="https://www.youtube.com/iframe_api" async></script>
  </Helmet>
)

export default Head
