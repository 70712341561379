// extracted by mini-css-extract-plugin
export var address = "Footer-module--address--E-atg";
export var certs = "Footer-module--certs--ZDcAD";
export var container = "Footer-module--container--1m2NM";
export var copyright = "Footer-module--copyright--pOC0O";
export var core = "Footer-module--core--NA5Wr";
export var info = "Footer-module--info--ATC3a";
export var logo = "Footer-module--logo--Y7p85";
export var nav = "Footer-module--nav--fTXBV";
export var outlined = "Footer-module--outlined--309-k";
export var socials = "Footer-module--socials--pWcv+";
export var tiki = "Footer-module--tiki--vCzQo";