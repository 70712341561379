import React, { forwardRef } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import cn from 'classnames'
import * as st from '~/assets/styl/Loading.module.styl'

interface Props {
  visible: boolean
  className?: string
  staticStyle?: boolean
}

const Loading = forwardRef(
  (
    { visible, className, staticStyle }: Props,
    ref: React.RefObject<HTMLDivElement>
  ) => (
    <div
      className={cn(
        staticStyle ? st.staticStyle : st.core,
        visible && st.visible,
        className
      )}
      ref={ref}
    >
      <CircularProgress style={{ color: '#23262E' }} size={25} />
    </div>
  )
)
Loading.displayName = 'Loading'

export default Loading
