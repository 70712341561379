import React from 'react'
import styled, { css } from 'styled-components'
import cn from 'classnames'
import * as st from '~/assets/styl/ImageSection.module.styl'

interface ImageInterface {
  image: string
  film?: boolean
}

function film(apply?: boolean) {
  if (apply)
    return 'linear-gradient(0deg, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0.4) 100%), '
  return ''
}

export const Image = styled.div<ImageInterface>`
  padding-top: 30%;
  position: relative;
  ${(props) =>
    props.image &&
    css`
      background: ${film(props.film)}url(${props.image}) center / cover;
    `}
  @media (max-width: 900px) {
    padding-top: 60%;
  }
`

interface Props {
  src: string
  triangle?: boolean
}

const ImageSection = ({ src, triangle = true }: Props) => (
  <Image image={src} className={cn(triangle && st.triangle)} />
)

export default ImageSection
