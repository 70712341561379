import React from 'react'
import { Image } from '~/components/ImageSection'
import { useMediaQuery } from 'react-responsive'
import { Button, Link } from '~/components'
import cn from 'classnames'
import * as st from '~/assets/styl/EnterpriseCard.module.styl'

export interface Props {
  name: string
  slug: string
  resume: string
  neighborhood: string
  city: string
  state: string
  tag: string
  imageFeature: string
  imageSideFirst: string
  imageSideSecond: string
  imageLogo: string
  sold: boolean
  isEnterprises?: boolean
}

const Enterprise = ({
  imageFeature: thumbnail,
  tag,
  name,
  city,
  state,
  neighborhood,
  slug,
  isEnterprises,
}: Props) => {
  const desktop = useMediaQuery({
    query: '(min-width: 701px)',
  })
  const location = `${neighborhood} - ${city} - ${state}`

  const Content = ({ desktop = false }: { desktop?: boolean }) => (
    <>
      <Image className={st.thumbnail} image={thumbnail} />
      <div
        className={cn(st.description, { [st.isEnterprises]: isEnterprises })}
      >
        <span className={st.tag}>{tag}</span>
        <h4>{name}</h4>
        <p className={st.location}>{location}</p>
        <Button href={'/empreendimento/' + slug + '/'}>
          Conheça o empreendimento
        </Button>
      </div>
    </>
  )

  return desktop ? (
    <div className={st.core}>
      <Content desktop />
    </div>
  ) : (
    <Link href={'/empreendimento/' + slug + '/'} className={st.core}>
      <Content />
    </Link>
  )
}

export default Enterprise
